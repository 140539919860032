<template>
 <div class="UxMobileSideBar">
  <div class="UxMobileSideBar__Permissions">
   <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 384 512"
    width="15px"
    height="15px"
    fill="rgb(255, 65, 73)"
   >
    <path
     d="M192 0C139 0 96 43 96 96V256c0 53 43 96 96 96s96-43 96-96V96c0-53-43-96-96-96zM64 216c0-13.3-10.7-24-24-24s-24 10.7-24 24v40c0 89.1 66.2 162.7 152 174.4V464H120c-13.3 0-24 10.7-24 24s10.7 24 24 24h72 72c13.3 0 24-10.7 24-24s-10.7-24-24-24H216V430.4c85.8-11.7 152-85.3 152-174.4V216c0-13.3-10.7-24-24-24s-24 10.7-24 24v40c0 70.7-57.3 128-128 128s-128-57.3-128-128V216z"
    />
   </svg>
   <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 576 512"
    width="15px"
    height="15px"
    fill="rgb(255, 65, 73)"
   >
    <path
     d="M0 128C0 92.7 28.7 64 64 64H320c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128zM559.1 99.8c10.4 5.6 16.9 16.4 16.9 28.2V384c0 11.8-6.5 22.6-16.9 28.2s-23 5-32.9-1.6l-96-64L416 337.1V320 192 174.9l14.2-9.5 96-64c9.8-6.5 22.4-7.2 32.9-1.6z"
    />
   </svg>
  </div>
  <div class="UxMobileSideBar__Checkpoints" @click="toggleShowPendingCheckpointsModal()">
   <div class="UxMobileSideBar__Tooltip" v-if="showTooltip">
    {{ $t('remember_show_tasks') }}
   </div>
   <div class="UxMobileSideBar__Checkpoints__Logo">
    <svg
     width="20"
     height="18"
     viewBox="0 0 20 18"
     fill="null"
     xmlns="http://www.w3.org/2000/svg"
    >
     <path
      d="M10 2.46362H19"
      stroke="#1F1F1F"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
     />
     <path
      d="M6.31818 1.19543L2.76932 3.73181L1 2.46362"
      stroke="#8134FF"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
     />
     <path
      d="M10 8.99994H19"
      stroke="#1F1F1F"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
     />
     <path
      d="M6.31818 7.73181L2.76932 10.2682L1 9"
      stroke="#8134FF"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
     />
     <path
      d="M10 15.5364H19"
      stroke="#1F1F1F"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
     />
     <path
      d="M6.31818 14.2682L2.76932 16.8046L1 15.5364"
      stroke="#8134FF"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
     />
    </svg>
   </div>
   <div class="UxMobileSideBar__Checkpoints__Light"></div>
  </div>
  <div class="UxMobileSideBar__NextCheckpoint" v-if="nextCheckpoint != ''">
   <div class="UxMobileSideBar__NextCheckpoint__Logo">
    <Transition name="completed">
     <svg
      v-if="checkPointCompleted"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      width="20px"
      height="20px"
      fill="green"
     >
      <path
       d="M243.8 339.8C232.9 350.7 215.1 350.7 204.2 339.8L140.2 275.8C129.3 264.9 129.3 247.1 140.2 236.2C151.1 225.3 168.9 225.3 179.8 236.2L224 280.4L332.2 172.2C343.1 161.3 360.9 161.3 371.8 172.2C382.7 183.1 382.7 200.9 371.8 211.8L243.8 339.8zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"
      />
     </svg>
    </Transition>
    <Transition name="completed">
     <svg
      v-if="!checkPointCompleted"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      width="20px"
      height="20px"
      fill="rgb(166, 166, 166)"
     >
      <path
       d="M243.8 339.8C232.9 350.7 215.1 350.7 204.2 339.8L140.2 275.8C129.3 264.9 129.3 247.1 140.2 236.2C151.1 225.3 168.9 225.3 179.8 236.2L224 280.4L332.2 172.2C343.1 161.3 360.9 161.3 371.8 172.2C382.7 183.1 382.7 200.9 371.8 211.8L243.8 339.8zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"
      />
     </svg>
    </Transition>
   </div>
   <div class="UxMobileSideBar__NextCheckpoint__Message">
    {{ checkPointMessage }}
   </div>
  </div>
  <div class="UxMobileSideBar__CheckpointsFinished" v-if="nextCheckpoint == ''">
   <button
    type="button"
    name="button"
    @click="sendToQuestions()"
    class="WatUX-button"
   >
    {{ $t('goquestion_mode') }}
   </button>
  </div>
 </div>
</template>
<script>
import gsap from 'gsap'
import Swal from 'sweetalert2'

export default {
 name: 'UxMobileSideBar',
 props: {
  checkPoints: {
   type: Array,
   default: null,
  },
  taskDescription: {
    type: String,
    default: null
  }
 },
 watch: {
  nextCheckpoint(newCheckpoint) {
   this.handleCheckpointChange(newCheckpoint)
  },
 },
 methods: {
  async handleCheckpointChange(newCheckpoint) {
   if (newCheckpoint.message == this.checkPoints[0].message) {
    return
   }
   this.checkPointCompleted = true
   await gsap.to('.UxMobileSideBar__NextCheckpoint__Message', {
    opacity: 0.3,
    duration: 0.5,
   })
   await this.timeout(2000)
   await gsap.to('.UxMobileSideBar__NextCheckpoint', {
    opacity: 0,
    duration: 1,
   })
   this.checkPointMessage = newCheckpoint.message
   this.checkPointCompleted = false
   await gsap.to('.UxMobileSideBar__NextCheckpoint__Message', {
    opacity: 1,
    duration: 0,
   })
   await gsap.to('.UxMobileSideBar__NextCheckpoint', {
    opacity: 1,
    duration: 0.3,
   })
  },
  timeout(ms) {
   return new Promise((resolve) => setTimeout(resolve, ms))
  },
  sendToQuestions() {
    if (!this.checkPoints || this.checkPoints.length == 0) {
        Swal.fire({
            title: 'Comprueba que hayas realizado todas las tareas',
            type: "warning",
            text: 'Si continuas sin haber finalizado todas las tareas el test no será válido',
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar'
        }).then((result)=>{
            if (result.isConfirmed) {
                this.$emit('sendToQuestions')
            }
        })
    }else{
        this.$emit('sendToQuestions')
    }
  },
  toggleShowPendingCheckpointsModal(){
    this.showTooltip = false
    this.$emit('toggleShowPendingCheckpointsModal')
  }
 },
 computed: {
  nextCheckpoint: {
    get(){
        if (!this.checkPoints) {
            return ''
        }
        const nextCheckpoint = this.checkPoints.find(
            (checkPoint) => checkPoint.completed == false
        )
        if (!nextCheckpoint) {
            return ''
        }
        return nextCheckpoint
    },
    set(newValue){

    }
   
  },
 },
 beforeMount() {
    if (!this.checkPoints) {
        this.areCheckpoints = false
        return;
    }
    this.checkPointMessage = this.checkPoints[0].message
 },
 mounted(){
    let vm = this
    setTimeout(function() {
        vm.showTooltip = false
    }, 10000);
 },
 data() {
  return {
   checkPointCompleted: false,
   showCheckpoint: true,
   checkPointMessage: '',
   areCheckpoints: true,
   showTooltip: true
  }
 },
}
</script>
<style lang="scss" scoped>
.completed-enter-active,
.completed-leave-active {
 transition: opacity 0.5s ease;
}

.completed-enter-from,
.completed-leave-to {
 opacity: 0;
}

.UxMobileSideBar {
 display: block;
 /*@media only screen and (min-width: 770px) {
  display: none;
 }*/
 order: 2;
 display: flex;
 flex-direction: row;
 align-items: center;
 column-gap: 20px;
 padding: 10px;
 &__Permissions {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
 }
 &__NextCheckpoint {
  flex-grow: 1;
  height: fit-content;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  margin-left: 250px;
  column-gap: 10px;
  svg {
   min-width: 20px;
  }
  &__Logo {
   position: relative;
   width: 20px;
   svg {
    position: absolute;
    top: 0;
    left: 0;
   }
  }
  &__Message {
   flex-grow: 1;
   display: -webkit-box;
   -webkit-line-clamp: 2;
   -webkit-box-orient: vertical;
   line-height: 15px;
   height: 30px;
   overflow: hidden;
   font-size: 12px;
   text-align: left;
  }
 }
 &__CheckpointsFinished {
  flex-grow: 1;
  height: fit-content;
  display: flex;
  justify-content: flex-end;
  .WatUX-button {
   background: var(--button-color);
   font-family: 'Bariol';
   font-weight: 400;
   font-size: 12px;
   border-radius: 3px;
   border: none;
   padding: 0.8rem 1rem;
   letter-spacing: 1px;
   text-transform: uppercase;
   color: white;
   width: 100%;
   max-width: 200px;
   &:disabled {
    opacity: 0.5;
   }
  }
  .WatUX-button:hover{
    background-color: #a3c440;
    cursor:pointer;
  }
 }
 &__Checkpoints {
  cursor: pointer;
  height: 100%;
  aspect-ratio: 1 / 1;
  border-radius: 10px;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  &__Logo {
   height: 18px;
  }
 }
 &__Tooltip{
    position: absolute;
    border: 1px solid lightgray;
    padding: 5px;
    background: white;
    font-family: 'Bariol';
    bottom: 10px;
    width: 200px;
    right: 1200px;
    &::before{
        content: "";
        position: absolute;
        left: -16px;
        bottom: 22px;
        border-width: 8px;
        border-style: solid;
        border-color: transparent lightgray transparent transparent;
    }
 }
}
</style>
